$color-brand1: #E5F6FA;
$color-brand2: #ABDFED;
$color-brand3: #43C4F4;
$color-brand4: #046295;
$color-brand5: #003863;

$color-primary1: #EBF4FF;
$color-primary2: #7BB4FA;
$color-primary3: #1072EA;
$color-primary4: #03428E;
$color-primary5: #00224D;

$color-default0: #FFFFFF;
$color-default1: #F2F3F5;
$color-default2: #C1CAD6;
$color-default3: #8493A6;
$color-default4: #5D6F85;
$color-default5: #1F2833;
$color-default6: #1C1C1C;

$color-urgent1: #FFE6E6;
$color-urgent2: #FF5967;
$color-urgent3: #E42545;
$color-urgent4: #990026;

$color-warning1: #FFF7CC;
$color-warning2: #FFD454;
$color-warning3: #F7A700;
$color-warning4: #8A4700;

$color-success1: #DFF7E3;
$color-success2: #62C493;
$color-success3: #07875C;
$color-success4: #006252;

$color-app-background: #F2F3F5;
$color-shadow: #1F2833;
$color-overlay: rgba(31, 40, 51, 0.4); // #1F2833, 40%;
$color-box-shadow: #D2D4D6;
$color-bf-button-background: #a4298fff;
