@import '/styles/colors';

.mobileBanner {
  display: flex;
  height: 84px;
  align-items: center;
  background: $color-brand1;
  padding: 5px 0;

  &__close {
    cursor: pointer;
    margin-left: 10px;
  }

  &__logo {
    display: flex;
    width: 95px;
    height: 60px;
    background-color: $color-brand3;
    margin-left: 10px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
  }

  &__logo svg {
    padding-top: 5px;
    width: 50px;
    fill: $color-default0;
  }

  &__links {
    display: flex;
    text-align: left;
    margin-left: 10px;

    &__title {
      font-size: 20px;
      font-weight: bold;
    }

    .mobileBannerWrapper {
      margin-right: 10px;

      .mobileBannerRating div {
        color: $color-default4;
      }
    }

    &__body {
      margin: 3px 0;
      padding-top: 5px;
      color: $color-default5;
      letter-spacing: -0.1px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;

      &--french {
        font-size: 12px;
      }
    }

    .mobileBannerBtnWrapper {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 76px;
      height: 28px;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      background: $color-primary3;
      border-radius: 4px;
      color: $color-default0;
      text-align: center;

      &--french {
        width: 84px;
        font-weight: normal;
      }
    }
  }

  .myFlippIcon {
    width: 60px;
    height: 60px;
  }
}
