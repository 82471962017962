@import '/styles/colors';
.ratingWrapper {
  width: 100%;
  height: 100%;
}

.starsWrapper {
  display: flex;
  margin-bottom: 9px;

  .reviews {
    color: $color-default5;
    margin-left: 7px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
  }
}
