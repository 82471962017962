@import '/styles/colors';

.header {
  padding: 15px 15px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.headerNav {
  display: flex;
  align-items: center;
  @media all and (max-width: 767px) {
    display: none;
  }
}

.heroImage {
  height: 422px;
  margin: 20px 0;
  background-size: cover;
  &:global(.avif) {
    background-image: url(/landing/new-hero-us.avif);
  }
  &:global(.webp) {
    background-image: url(/landing/new-hero-us.webp);
  }
  &:global(.fallback) {
    background-image: url(/landing/new-hero-us.jpg);
  }
  @media screen and (max-width: 768px) {
    height: 155px;
    margin: 10px 0;
    &:global(.avif) {
      background-image: url(/landing/new-mobile-hero-us.avif);
    }
    &:global(.webp) {
      background-image: url(/landing/new-mobile-hero-us.webp);
    }
    &:global(.fallback) {
      background-image: url(/landing/new-mobile-hero-us.jpg);
    }
  }
}

.bfHero {
  overflow: hidden;
  position: relative;
  margin-top: 10px;
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(https://f.wishabi.net/creative/Distribution/images/hpb-bf2024-1593.jpg);
  @media screen and (max-width: 1113px) {
    background-image: url(https://f.wishabi.net/creative/Distribution/images/hpb-bf2024-1113.jpg);
  }
  @media screen and (max-width: 992px) {
    background-image: url(https://f.wishabi.net/creative/Distribution/images/hpb-bf2024-992.jpg);
  }
  @media screen and (max-width: 736px) {
    margin-bottom: 15px;
    padding: 0 50px 0 50px;
    box-sizing: border-box;
    background-image: url(https://f.wishabi.net/creative/Distribution/images/hpb-bf2024-736.jpg);
  }
  @media screen and (max-width: 390px) {
    background-image: url(https://f.wishabi.net/creative/Distribution/images/hpb-bf2024-390.jpg);
  }
  img {
    max-height: 562px;
    object-fit: cover;
    object-position: center center;
    @media screen and (min-width: 1440px) {
      display: block;
      width: 100%;
    }
  }
  .bfPostal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bfTitle {
    color: $color-default6;
    font-size: 36px;
    font-weight: 900;
    line-height: 46px;
    text-align: center;
    margin-bottom: -15px;
    font-family: "Circular Std", sans-serif;
    @media screen and (max-width: 768px) {
      font-size: 32px;
      line-height: 40px;
      min-width: 300px;
    }
  }
  .cwTitle {
    margin-bottom: 40px;
  }
  .bfInput {
    input {
      background-color: $color-default0;
      border-radius: 4px;
      outline: none;
      border: none;
      height: 46px;
      font-size: 26px;
      max-width: 197px;
      font-weight: 600;
      padding-left: 10px;
      @media screen and (max-width: 768px) {
        max-width: 153px;
      }
    }
  }
  .notValid {
    input {
      border: 1px solid;
      border-color: var(--red);
    }
  }
  .bfWrap {
    display: flex;
    position: relative;
    .notValidInfo {
      position: absolute;
      bottom: -80%;
      z-index: 1;
      font-size: 16px;
      color: var(--red);
    }
    a {
      margin-left: 16px;
      font-size: 18px;
      color: $color-default0;
      outline: none;
      border: none;
      border-radius: 4px;
      background-color: $color-primary3;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 130px;
      text-align: center;
      padding: 0 8px 0 8px;
      @media screen and (max-width: 768px) {
        width: 121px;
        font-size: 14px;
      }
    }
  }
}

.mobileMenu {
  display: flex;
  padding: 0 10px;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  top: 76px;
  background-color: var(--white);
  box-shadow: 0 7px 10px 0 rgb(0 0 0 / 30%);
  @media all and (min-width: 767px) {
    display: flex;
    width: auto;
    flex-direction: row;
    position: static;
    border: none;
    box-shadow: none;
  }
  .link {
    height: 56px;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 10px 0;
    border-top: 1px solid var(--gray-5);
    font-size: 13px;
    font-weight: 400;
    &:hover {
      border-color: var(--gray-5);
    }
    @media all and (min-width: 767px) {
      margin-left: 30px;
      height: auto;
      width: auto;
      border: none;
    }
  }
}

.link {
  display: flex;
  margin-left: 30px;
  border-bottom: 1px solid transparent;
  font-size: 15px;
  cursor: pointer;
  color: var(--gray-4);
  transition: border-bottom-color 0.3s ease-in-out;
  text-decoration: none;
  &:hover {
    border-color: var(--gray-4);
  }

  @media all and (max-width: 800px) {
    margin-left: 20px;
  }
}

.headerLogo {
  min-width: 57px;
  width: 60px;
  height: 40px;
  margin-right: 13px;
  position: relative;
  z-index: 2;
  svg {
    fill: var(--flip-color-3);
  }
}

.searchInput {
  margin-top: 27px;
  width: 100%;
  height: 52px;
  min-width: 290px;
  max-width: 330px;
  border: 1px solid var(--gray-2);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    padding-left: 10px;
    width: 120px;
    font-size: 26px;
    border: none;
    font-weight: 600;
    font-family: SourceSansPro, sans-serif;
    &:focus,
    &:active {
      outline: none;
      border-color: transparent;
      &::placeholder {
        color: transparent;
      }
    }
    &::placeholder {
      opacity: 0.73;
      color: inherit;
    }
    &:focus-visible {
      outline: none;
    }
  }
  @media all and (max-width: 767px) {
    max-width: none;
    width: 90%;
    margin: 0 auto 20px;
  }
}

.notValid {
  border-color: var(--red);
}

.disabled {
  pointer-events: none;
}

.searchProductInput {
  @extend .searchInput;

  width: 505px;
  max-width: none;
  margin-top: -10px;

  @media all and (max-width: 650px) {
    width: 100%;
  }

  input {
    font-size: 18px;
    width: 340px;
    font-family: SourceSansProLight, sans-serif;
    &::placeholder {
      color: var(--gray-0);
    }
  }
}

.mobile {
  width: 100%;
  display: none;
  @media all and (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.navHamburger {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  position: relative;
  z-index: 2;
  svg {
    width: 22px;
    height: 22px;
    margin: auto;
    fill: var(--gray-2);
    &:hover {
      fill: var(--gray-4);
    }
  }
}

.homepage {
  color: var(--gray-4);
  font-family: SourceSansPro, sans-serif;
}

.zipContainer {
  margin: 90px auto 0;
  justify-content: center;
  align-items: flex-start;

  @media all and (max-width: 1500px) {
    margin: 0 auto;
    padding-right: 0;
  }

  @media all and (max-width: 1300px) {
    margin: 60px 0 40px;
  }

  @media all and (max-width: 1000px) {
    margin: 50px 0 0;
  }

  @media all and (max-width: 767px) {
    flex-direction: column;
  }

  @media all and (max-width: 530px) {
    margin: 20px 0 0;
  }
}

.zipSearchBar {
  margin: 40px 0 0 30px;
  position: relative;
  width: 100%;
  max-width: 600px;

  @media all and (max-width: 1300px) {
    margin: 0 0 0 30px;
    max-width: 450px;
  }

  @media all and (max-width: 767px) {
    margin: 0 0 40px;
    max-width: 100%;
  }

  > p {
    font-family: SourceSansProLight, sans-serif;
    @extend .zipSubTitle;
  }

  .notValidInfo {
    margin: 0;
    position: absolute;
    z-index: 1;
    color: var(--red);

    > p {
      margin: 0;
    }
  }
}

.zipTitle {
  margin: 40px 25px 60px 0;
  max-width: 590px;
  font-size: 37px;
  font-weight: 300;
  line-height: 50px;
  color: var(--gray-4);
  font-family: SourceSansProLight, sans-serif;

  @media all and (max-width: 1300px) {
    max-width: 500px;
    margin: 0;
  }

  @media all and (max-width: 1000px) {
    padding: 0 50px;
    max-width: none;
  }

  @media all and (max-width: 767px) {
    font-size: 30px;
    padding: 0;
    text-align: center;
  }

  @media all and (max-width: 530px) {
    font-size: 24px;
    line-height: 30px;
  }
}

.zipSubTitle {
  font-size: 18px;
  margin-top: 15px;
  line-height: 32px;
  strong {
    font-family: SourceSansPro, sans-serif;
  }

  @media all and (max-width: 767px) {
    text-align: center;
  }
}

.storesWrapper {
  margin-top: 60px;
  align-items: flex-start;
  justify-content: center;

  @media all and (max-width: 1000px) {
    margin-top: 20px;
    flex-direction: column;
  }

  @media all and (max-width: 530px) {
    margin-top: 0;
  }
}

.stores {
  width: 34%;
  min-width: 400px;
  padding: 20px 30px 0 70px;

  @media all and (max-width: 1000px) {
    margin-bottom: 30px;
    width: 100%;
    min-width: auto;
    padding: 0;
  }

  > p {
    font-family: SourceSansProLight, sans-serif;

    &:first-child {
      @extend .storesTitle;
      margin-top: 0;

      strong {
        font-family: SourceSansPro, sans-serif;
      }
    }
    &:last-of-type {
      @extend .storesParagraph;
    }
  }
}

.storesTitle {
  line-height: normal;
  max-width: 410px;
  font-size: 31px;
  margin-bottom: 20px;
  margin-top: -10px;

  @media all and (max-width: 1000px) {
    text-align: center;
    max-width: 100%;
  }
}

.storesLink {
  font-weight: 700;
  color: var(--gray-4);
  text-decoration: underline;
  padding-right: 5px;
  cursor: pointer;
}

.storesParagraph {
  line-height: 24px;

  @media all and (max-width: 1000px) {
    width: 75%;
    margin: 0 auto 15px;
    text-align: center;
  }
}

.storesImgWrapper {
  padding: 0 15px;
  margin: 45px 0 30px -30px;
  align-items: center;
  flex-wrap: wrap;

  @media all and (max-width: 1500px) {
    margin: 50px 0 0 0;
  }

  @media all and (max-width: 530px) {
    margin: 20px 0 0 0;
  }
}

.storesImg {
  width: 68%;
}

.storesLogo {
  margin-bottom: 15px;
  text-align: center;
  width: 25%;
}

.button {
  display: flex;
  align-items: center;
  align-content: center;
  margin: 15px auto;
  justify-content: center;
  font-size: 14px;
  background-color: var(--flipp-color-1);
  border-radius: 4px;
  color: var(--white);
  min-width: 50px;
  transition: background-color 0.1s linear;
  width: 186px;
  height: 42px;
  text-decoration: none;
  &:hover {
    background-color: var(--flipp-color-2);
  }
}

.searchButton {
  @extend .button;
  text-align: center;
  width: 140px;
  margin: 7px;
  cursor: pointer;
}

.coupons {
  @media all and (max-width: 1000px) {
    flex-direction: column-reverse;
  }
}

.couponsInner {
  width: 37%;
  padding: 0 45px 0 120px;

  @media all and (max-width: 1000px) {
    width: 100%;
    padding: 0;
  }

  > p {
    &:first-child {
      max-width: 370px;
      margin-top: 40px;

      @media all and (max-width: 1000px) {
        max-width: 100%;
      }
    }
    &:last-of-type {
      @extend .storesParagraph;
    }
  }
}

.couponsLogos {
  justify-content: flex-start;

  .storesLogo {
    @media all and (max-width: 1000px) {
      width: 33.33%;
    }
  }
}

.couponLogoInner {
  margin: auto;
  display: flex;
  justify-content: center;
  max-width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 30%);

  @media all and (max-width: 530px) {
    transform: scale(0.75);
  }
}

.searchItems {
  width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  padding: 65px 10px 110px;

  @media all and (max-width: 660px) {
    padding: 40px 10px;
    width: 90%;
  }

  > p {
    @extend .searchItemsTitle;
    font-family: SourceSansProLight, sans-serif;

    strong {
      font-family: SourceSansPro, sans-serif;
    }
  }
}

.searchItemsTitle {
  text-align: center;
  font-size: 34px;
}

.downloadApp {
  padding-bottom: 35px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--gray-1);
  width: 100%;

  @media all and (max-width: 950px) {
    flex-direction: column;
  }
}

.downloadAppTitle {
  font-size: 31px;
}

.downloadAppInfo {
  padding: 75px 100px 0 0;
  max-width: 480px;

  @media all and (max-width: 950px) {
    max-width: 75%;
    margin: auto;
    text-align: center;
    padding: 65px 0;
  }

  @media all and (max-width: 530px) {
    padding: 0 0 20px;
  }

  > p {
    font-family: SourceSansProLight, sans-serif;
    &:first-child {
      @extend .downloadAppTitle;
      margin: 0;

      strong {
        font-family: SourceSansPro, sans-serif;
      }
    }
  }
}

.downloadAppParagraph {
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
}

.buttons {
  display: flex;
  margin: 50px 0 0 30px;

  a {
    margin: 0 5px;
    display: block;
    width: 170px;
    height: 48px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .buttonIOS {
    background-image: url(../../../public/landing/ios-appstore-en.png);
  }

  .buttonAndroid {
    background-image: url(../../../public/landing/android-appstore-en.png);
  }
}

.downloadAppImg {
  margin-top: -40px;

  @media all and (max-width: 950px) {
    display: none;
  }
}

.scrollUp {
  position: relative;
  right: 20px;
  margin-bottom: -55px;
  padding: 10px;
  fill: var(--gray-2);
  height: 33px;
  min-width: 33px;
  width: 33px;
  align-self: flex-end;
  border-radius: 50%;
  box-shadow: 2px 0 4px 0 rgb(0 0 0 / 20%);
  transform: rotate(90deg);
  background-color: var(--white);
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 2px 0 4px 0 rgb(0 0 0 / 50%);
  }
}

.footer {
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1280px;
  margin: auto;

  @media all and (max-width: 660px) {
    flex-direction: column;
  }
}

.footerListWrapper {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;

  @media all and (max-width: 660px) {
    width: 100%;
    justify-content: space-around;
  }
}

.footerBlog {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media all and (max-width: 660px) {
    width: 100%;
  }
}

.footerBlogTitle {
  max-width: 250px;
  font-size: 15px;
  font-weight: 300;
  line-height: 32px;
  color: var(--gray-3);
  font-family: SourceSansProLight, sans-serif;

  @media all and (max-width: 660px) {
    margin: 20px auto 0;
    max-width: none;
  }
}

.footerList {
  margin: 0 70px 0 40px;
  list-style-type: none;

  @media all and (max-width: 850px) {
    margin: 0 40px 0 0;
  }

  @media all and (max-width: 767px) {
    margin: 0;
  }
}

.footerItem {
  margin-bottom: 10px;
  font-size: 13px;
}

.footerItemLink {
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-color 0.1s ease;
  color: var(--gray-3);
  font-family: SourceSansProLight, sans-serif;
  &:hover {
    border-color: var(--gray-4);
  }
}

.footerButton {
  @extend .button;
  width: 140px;
  font-size: 14px;
  font-weight: 500;
  margin: 0;

  @media all and (max-width: 767px) {
    margin: 20px auto 0;
  }
}

.divider {
  width: 100%;
  margin: 0 auto;
  max-width: 1280px;
  height: 2px;
  background-color: var(--gray-5);
}

.copyright {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  padding: 30px 0;
  text-align: center;
  color: var(--gray-3);
}

.copyrightContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    margin: 0 8px 10px;
    display: block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .fb {
    background-image: url(../../../public/landing/fb.png);
  }
  .insta {
    background-image: url(../../../public/landing/Instagram.png);
  }
  .linkedin {
    background-image: url(../../../public/landing/linkedin.png);
  }
}
